export const modes = {
    "tolerante": false,
    "zen": true
};

function persistModes() {
    localStorage.setItem("modes", JSON.stringify(modes));
}

function loadModes() {
    const data = localStorage.getItem("modes");
    if(data){
        Object.assign(modes, JSON.parse(data));
    }
}

function updateModes() {
    document.body.classList.toggle("zen", modes.zen);

    const items : HTMLElement[] = Array.from(document.querySelectorAll("[data-mode]"));

    for(const item of items){
        const mode = String(item.dataset.mode);
        item.classList.toggle("active", modes[mode]);
    }
}

function modeClick(event : MouseEvent) : void {
    const item = event.target as HTMLElement;

    if(item.dataset.mode){
        const activo = item.classList.toggle("active");
        const mode = item.dataset.mode;

        modes[mode] = activo;

        updateModes();
        persistModes();
    }
}

export function setupModes() : void {
    loadModes();
    updateModes();
    document.addEventListener("click", modeClick);
}
