const $ppm = document.getElementById("ppm");
const $fail = document.getElementById("fails");
const $total = document.getElementById("total");
const $time = document.getElementById("time");

let timer : NodeJS.Timer;
let started = false;
let startTime: Date;
let hits = 0;
let fails = 0;
let totalSeconds = 0;
let total = 0;
let ppm = 0;

export function start() : void {
    timer = setInterval(update, 100);
}

export function stop() : void {
    clearInterval(timer);
}

export function hit() : void {
    if (!started) {
        startTime = now();
        started = true;
    }
    hits++;
}

export function fail() : void {
    if (!started) {
        startTime = now();
        started = true;
    }
    fails++;
}

export function reset() :void {
    totalSeconds = 0;
    startTime = now();
    started = false;
    hits = 0;
    fails = 0;
    ppm = 0;
}

function formatTime(val: number) : string {
    return `0${Math.floor(val)}`.slice(-2);
}

function displayTime(seconds: number): string {
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;

    return [hours, minutes, seconds % 60].map(formatTime).join(":");
}

function now() : Date {
    return new Date();
}

function update() : void {
    calcularPpm();

    if(!$ppm){
        throw new Error("No se ha encontrado el elemento #ppm");
    }

    if(!$fail){
        throw new Error("No se ha encontrado el elemento #fail");
    }

    if(!$total){
        throw new Error("No se ha encontrado el elemento #total");
    }

    if(!$time){
        throw new Error("No se ha encontrado el elemento #time");
    }

    $ppm.innerText = String(ppm);
    $fail.innerText = String(fails);
    $total.innerText = String(hits);
    $time.innerText = displayTime(totalSeconds);
}

function getTotalSeconds() : number {
    return Math.ceil((now().getTime() - startTime.getTime()) / 1000);
}

function calcularPpm() : void {
    if (!started) {
        return;
    }

    total = hits + fails;
    totalSeconds = getTotalSeconds();
    ppm = Math.max(0, Math.ceil((total / 5 - fails) / (totalSeconds / 60)));
}
