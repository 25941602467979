import { Book } from "../books";
import * as library from "./library";
import * as stats from "./stats";
import { setupModes, modes } from "./modes";

const $caption = document.getElementById("caption");
const $screen = document.getElementById("screen");
const $bookAuthor = document.getElementById("book-author");
const $status = document.getElementById("status");
const $writebox = document.getElementById("writebox");

let cursorPosition = 0;
let letters: HTMLElement[] = [];
let currentBook: Book = library.loadWelcome();
let currentPage = 0;

function loadNextBook(): void {
    const book = library.nextBook();
    loadBook(book);
}

function currentChar(): string {
    return letters[cursorPosition].innerText;
}

function prepareContent(content: string): string {
    return content
        .split("")
        .map(function (c) {
            return "<em>" + c + "</em>";
        })
        .join("");
}

function loadBook(book: Book): void {
    if (!$caption || !$bookAuthor) {
        throw new Error("No se ha encontrado el elemento #caption");
    }

    currentBook = book;
    $caption.innerText = book.title;
    $bookAuthor.innerHTML = book.author;

    loadPage(0);
}

function loadPage(num: number) {
    if (!$screen) {
        throw new Error("No se ha encontrado el elemento #screen");
    }
    if (!$status) {
        throw new Error("No se ha encontrado el elemento #status");
    }

    currentPage = num;
    cursorPosition = 0;
    $screen.innerHTML = prepareContent(currentBook.pages[currentPage]);
    letters = Array.from($screen.querySelectorAll("em"));

    const p = currentPage + 1;
    const t = currentBook.pages.length;
    $status.innerText = `Página ${p} de ${t}`;
}

function nextPage(): void {
    if (currentPage < currentBook.pages.length - 1) {
        loadPage(currentPage + 1);
    } else {
        loadNextBook();
    }
}

function resetText(): void {
    cursorPosition = 0;
    moveToEm(letters[0]);
}

function moveToEm(em: HTMLElement): void {
    cursorPosition = Array.from(letters).indexOf(em);
    letters.forEach(function (item, index) {
        if (index < cursorPosition) {
            item.classList.add("ok");
        } else {
            item.classList.remove("ok");
        }
    });
}

function ensureView(): void {
    letters[cursorPosition].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
    });
}

function movePrev(): void {
    if (cursorPosition > 0) {
        cursorPosition--;
        letters[cursorPosition].classList.remove("ok");
    }

    ensureView();
}

function moveNext(): void {
    letters[cursorPosition].classList.add("ok");
    if (cursorPosition < letters.length - 1) {
        cursorPosition++;
    } else {
        nextPage();
    }

    ensureView();
}

function prevent(event: Event): void {
    event.preventDefault();
}

function simplify(c: string) {
    c = c.toLocaleLowerCase();
    c = c.replace("á", "a");
    c = c.replace("é", "e");
    c = c.replace("í", "i");
    c = c.replace("ó", "o");
    c = c.replace("ú", "u");
    c = c.replace("ü", "u");
    c = c.replace("ñ", "n");

    return c;
}

function sameLeter(key: string, currentChar: string): boolean {
    const modoTolerante = modes.tolerante;

    if (modoTolerante) {
        key = simplify(key);
        currentChar = simplify(currentChar);
    }

    if (key == currentChar) {
        return true;
    }

    if (key == "-") {
        if (currentChar.codePointAt(0) == 8211 || currentChar == "—") {
            return true;
        }
    }

    return false;
}

function specialKeyPressed(event: KeyboardEvent) {
    return event.altKey || event.ctrlKey || event.shiftKey || event.metaKey;
}

function wbOnInput(event: Event) {
    const inputEvent = event as InputEvent;
    const key = String(inputEvent.data);

    if (key == "´" || key == "`") {
        return;
    }

    if (sameLeter(key, currentChar())) {
        moveNext();
        stats.hit();
    } else {
        stats.fail();
    }
}

function wbOnKeydown(event: KeyboardEvent) {
    switch (event.key) {
        case "Backspace":
            event.preventDefault();
            movePrev();
            break;
        case "Escape":
            event.preventDefault();
            resetText();
            stats.reset();
            break;
        case "Enter":
            event.preventDefault();
            loadNextBook();
            stats.reset();
            break;
        default:
            if (event.repeat) {
                event.preventDefault();
                return false;
            }
            break;
    }
}

function screenOnClick(event: MouseEvent): void {
    event.preventDefault();

    if (!event.target) {
        return;
    }

    const item: HTMLElement = event.target as HTMLElement;

    if (item.tagName == "EM") {
        moveToEm(item);
        stats.reset();
    } else {
        moveToEm(letters[0]);
    }

    focus();
}

function onClickOutside(event: MouseEvent): void {
    focus();
}

function focus(): void {
    if (!$writebox) {
        throw new Error("No se ha encontrado el elemento #writebox");
    }
    $writebox.focus();
}

function setupHandlers(): void {
    if (!$writebox) {
        throw new Error("No se ha encontrado el elemento #writebox");
    }

    if (!$screen) {
        throw new Error("No se ha encontrado el elemento #screen");
    }

    $writebox.addEventListener("cut", prevent);
    $writebox.addEventListener("copy", prevent);
    $writebox.addEventListener("paste", prevent);
    $writebox.addEventListener("input", wbOnInput);
    $writebox.addEventListener("keydown", wbOnKeydown);
    $screen.addEventListener("dblclick", screenOnClick);
    document.addEventListener("click", onClickOutside);
}

export function setupScreen(): void {
    setupModes();
    setupHandlers();
    stats.start();
    loadBook(currentBook);
    focus();
}
