function chunkString(str: string, len: number): string[] {
    const input = str.trim().split(" ");
    const output: string[] = [];
    let index = 0;

    output[index] = "";

    input.forEach((word) => {
        const temp = `${output[index]} ${word}`.trim();
        if (temp.length <= len) {
            output[index] = temp;
        } else {
            index++;
            output[index] = word;
        }
    });

    return output;
}

function normalizeContent(t: string): string {
    t = t.replace(`–`, "-");
    t = t.replace(`’`, "'");
    t = t.replace(`‘`, "'");
    t = t.replace(/\u2026/g, "...");
    t = t.replace(/[\s]+/gu, " ");

    return t.trim();
}

function splitIntoPages(content: string) {
    return chunkString(content, 800);
}

function prepareContent(content: string): string[] {
    const pages = splitIntoPages(normalizeContent(content));

    return pages;
}

export interface Book {
    title: string;
    author: string;
    about: string;
    pages: string[];
}

export const welcome: Book = {
    title: "Bienvenidos a mecanito.com",
    author: "Carlos Gant",
    about: "https://www.mecanito.com/",
    pages: prepareContent(require("bundle-text:./bienvenida.txt")),
};

export const books: Book[] = [
    {
        title: "El asno y el caballo",
        author: "Cristina Rodríguez Lomba",
        about: "https://www.safecreative.org/work/2001142873710-el-asno-y-el-caballo",
        pages: prepareContent(require("bundle-text:./asno-y-caballo.txt")),
    },
    {
        title: "Los carneros y el gallo",
        author: "Cristina Rodríguez Lomba",
        about: "https://www.safecreative.org/work/1911182504059-los-carneros-y-el-gallo",
        pages: prepareContent(require("bundle-text:./carneros-y-gallo.txt")),
    },
    {
        title: "Caperucita roja",
        author: "Cristina Rodríguez Lomba",
        about: "https://www.safecreative.org/work/1512166040183-caperucita-roja",
        pages: prepareContent(require("bundle-text:./caperucita-roja.txt")),
    },
    {
        title: "La encina y el junco",
        author: "Cristina Rodríguez Lomba",
        about: "https://www.safecreative.org/work/1909131919962-la-encina-y-el-junco",
        pages: prepareContent(
            require("bundle-text:./la-encina-y-el-junco.txt")
        ),
    },
    {
        title: "Abuelita",
        author: "Hans Christian Andersen",
        about: "",
        pages: prepareContent(require("bundle-text:./abuelita.txt")),
    },
    {
        title: "El elfo y el rosal",
        author: "Hans Christian Andersen",
        about: "",
        pages: prepareContent(require("bundle-text:./el-elfo-y-el-rosal.txt")),
    },
    {
        title: "El patito feo",
        author: "Hans Christian Andersen",
        about: "",
        pages: prepareContent(require("bundle-text:./el-patito-feo.txt")),
    },
    {
        title: "El secreto del árbol centenario",
        author: "Gemini",
        about: "",
        pages: prepareContent(
            require("bundle-text:./el-secreto-del-arbol-centenario.txt")
        ),
    },
];
