import { Book, books, welcome } from "../books";

function pickRandomBook(): Book {
    const index = Math.floor(Math.random() * books.length);
    const book = books[index];

    return book;
}

export function nextBook(): Book {
    return pickRandomBook();
}

export function loadWelcome(): Book {
    return welcome;
}
